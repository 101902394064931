import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FoundationWrapper from "./../../components/FoundationWrapper";
import Selector from "./../../components/Selector";
import ArticleBody from "./../../components/ArticleBody";
import { H3Strong } from "./../../components/Typography";
// import ProgrammeBody from "./../../components/ProgrammeBody";
// import EventGrid from "./../../components/EventGrid";
// import FoundationPeopleSelector from "./../../components/FoundationPeopleSelector";
import { BigButton } from "./../../components/Buttons";
import {
  ArticleBodyWrapper,
  AboutWrapper,
} from "./../../components/FoundationStyles/elements";

const FoundationGrantsPage = () => {
  const grantsPageQuery = useStaticQuery(graphql`
    {
      craftFoundationGrantsFoundationGrantsEntry {
        title
        subtitle
        applyLink
        foundationIsApplicationOpen
        callToAction
        programmeEventSelector {
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventLocation {
              slug
              title
              ... on Craft_community_communityMember_Entry {
                communityMemberLocation
                communityIsThisAnAlserkalProperty
              }
            }
            eventType
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        foundationResidents {
          ... on Craft_foundationResidents_resident_BlockType {
            residentTitle
            residentName
            residentBiography
            residentImage {
              url
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 225, width: 295, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        foundationResidencySelectionCommittee {
          ... on Craft_foundationResidencySelectionCommittee_selectionCommitteeMember_BlockType {
            selectionCommitteeMemberBiography
            selectionCommitteeMemberImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 225, width: 295, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            selectionCommitteeMemberTitle
            selectionCommitteeMemberName
          }
        }
        foundationResidenceApply
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  `).craftFoundationGrantsFoundationGrantsEntry;
  const {
    title,
    subtitle,
    richContent,
    callToAction,
    /* foundationResidencySelectionCommittee,
    foundationResidents, */
    programmeEventSelector,
    foundationResidenceApply,
    applyLink,
    foundationIsApplicationOpen,
  } = grantsPageQuery;
  // console.log(
  //   residencyPageQuery.foundationResidents.map((x) => x.residentImage)
  // );
  // const [shownEvents, setShownEvents] = React.useState([]); // Do we need this?

  // React.useEffect(() => {
  //   const eventList = [];
  //   // console.log("generating event list");
  //   for (let i = 0; i < programmeEventSelector.length; i++) {
  //     const newEvent = programmeEventSelector[i];
  //     newEvent.focusLevel = 0;
  //     eventList[eventList.length] = newEvent;
  //   }
  //   // console.log(eventList);
  //   setShownEvents(eventList);
  // }, []);

  return (
    <FoundationWrapper title={title} selected selection={3}>
      <Selector
        tabs={[
          {
            title: "About",
            content: (
              <AboutWrapper>
                <H3Strong>{subtitle}</H3Strong>
                <ArticleBodyWrapper>
                  <ArticleBody
                    subTitleColor={"var(--black)"}
                    mainColumn={richContent}
                    color={"var(--purple)"}
                    textColor={"var(--black)"}
                  />
                </ArticleBodyWrapper>
              </AboutWrapper>
            ),
          } /*
          {
            title: "Selection Committee",
            content: (
              <div id="selectioncommittee">
                <FoundationPeopleSelector
                  people={foundationResidencySelectionCommittee.map((x) => {
                    return {
                      name: x.selectionCommitteeMemberName,
                      title: x.selectionCommitteeMemberTitle,
                      image: x.selectionCommitteeMemberImage.length
                        ? x.selectionCommitteeMemberImage[0].slide
                        : null,
                      bio: x.selectionCommitteeMemberBiography,
                    };
                  })}
                />
              </div>
            ),
          },
          {
            title: "Past winners",
            content: (
              <div id="residents">
                <FoundationPeopleSelector
                  people={foundationResidents.map((x) => {
                    return {
                      name: x.residentName,
                      title: x.residentTitle,
                      image: x.residentImage.length
                        ? x.residentImage[0].slide
                        : null,
                      bio: x.residentBiography,
                    };
                  })}
                />
              </div>
            ),
          },
          {
            title: "Events",
            content: (
              <div id="events">
                <ProgrammeBody>
                  <EventGrid events={shownEvents} />
                </ProgrammeBody>
              </div>
            ),
          },*/,
          {
            title: "Apply",
            content: (
              <div id="apply">
                <ArticleBodyWrapper>
                  <ArticleBody
                    subTitleColor={"var(--black)"}
                    mainColumn={[
                      {
                        __typename: "Craft_richContent_richText_BlockType",
                        id: "1270",
                        typeHandle: "richText",
                        richText: foundationResidenceApply,
                      },
                    ]}
                    color={"var(--purple)"}
                    textColor={"var(--black)"}
                  />
                </ArticleBodyWrapper>
                {foundationIsApplicationOpen ? (
                  <p style={{ margin: "50px auto", textAlign: "center" }}>
                    <a href={applyLink}>
                      <BigButton color={"var(--black)"}>
                        {callToAction || "APPLY"}
                      </BigButton>
                    </a>
                  </p>
                ) : null}
              </div>
            ),
          },
        ]}
        color={"var(--purple)"}
      />
    </FoundationWrapper>
  );
};
export default FoundationGrantsPage;
